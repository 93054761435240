import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "pa-0"
  }, [_c(VCard, {
    style: _vm.cardStyle,
    attrs: {
      "outlined": "",
      "rounded": _vm.rounded
    }
  }, [_vm.editMode ? _c(VContainer, {
    staticClass: "d-flex justify-space-between flex-wrap"
  }, [_c(VBtnToggle, {
    staticClass: "mb-2",
    attrs: {
      "dense": "",
      "multiple": ""
    }
  }, [_c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('bold')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBold().run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-bold")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('italic')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleItalic().run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-italic")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('strike')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleStrike().run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-strikethrough-variant")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('code')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleCode().run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-code-tags")])], 1)]), _c(VBtnToggle, {
    staticClass: "mb-2",
    attrs: {
      "dense": ""
    }
  }, [_c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('heading', {
        level: 1
      })
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleHeading({
          level: 1
        }).run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-header-1")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('heading', {
        level: 2
      })
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleHeading({
          level: 2
        }).run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-header-2")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('heading', {
        level: 3
      })
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleHeading({
          level: 3
        }).run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-header-3")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('paragraph')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setParagraph().run();
      }
    }
  }, [_c(VIcon, [_vm._v("mdi-format-paragraph")])], 1)]), _c(VBtnToggle, {
    attrs: {
      "dense": ""
    }
  }, [_c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('bulletList')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBulletList().run();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-format-list-bulleted ")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('orderedList')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleOrderedList().run();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-format-list-numbered ")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('taskList')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleTaskList().run();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-format-list-checkbox ")])], 1), _c('button', {
    staticClass: "v-btn v-btn--flat v-btn--icon theme--light v-size--default v-btn--round grey lighten-3",
    class: {
      'v-item--active v-btn--active': _vm.editor.isActive('blockquote')
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBlockquote().run();
      }
    }
  }, [_c(VIcon, [_vm._v(" mdi-format-quote-open ")])], 1)])], 1) : _vm._e(), _vm.editMode ? _c(VDivider) : _vm._e(), _c(VRow, {
    staticClass: "pa-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c('EditorContent', {
    attrs: {
      "editor": _vm.editor
    }
  })], 1)], 1), _vm.optionalEditing ? _c(VCardActions, [_c(VSpacer), _vm.editMode && _vm.editingCancel ? _c('div', [_c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": "",
      "color": "error",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" Abbrechen ")]), _c(VBtn, {
    staticClass: "mx-2",
    attrs: {
      "small": "",
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v(" Speichern ")])], 1) : _vm._e(), !_vm.editMode ? _c(VBtn, {
    staticClass: "rounded-sm",
    attrs: {
      "small": "",
      "icon": "",
      "tile": "",
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleEdit();
      }
    },
    model: {
      value: _vm.editMode,
      callback: function ($$v) {
        _vm.editMode = $$v;
      },
      expression: "editMode"
    }
  }, [_c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-pencil ")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };