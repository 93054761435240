import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Placeholder from '@tiptap/extension-placeholder';
export default {
  components: {
    EditorContent
  },
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    optionalEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    editingCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    saveId: {
      type: Number,
      required: false,
      default: null
    },
    borderThickness: {
      type: Number,
      required: false,
      default: 1
    },
    borderStyle: {
      type: String,
      required: false,
      default: 'solid #9E9E9E'
    },
    rounded: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  data: () => ({
    editor: null,
    editMode: false,
    oldText: ''
  }),
  computed: {
    border() {
      return 'border: ' + this.borderThickness + 'px ' + this.borderStyle;
    },
    cardStyle() {
      return this.border;
    }
  },
  methods: {
    toggleEdit() {
      this.editMode = !this.editMode;
      this.editor.setEditable(this.editMode);
      this.oldText = this.editor.getHTML();
    },
    save() {
      var text = this.editor.getHTML();
      var returnValue = this.saveId == null ? text : {
        id: this.saveId,
        text: text
      };
      this.$emit('saved', returnValue);
      this.toggleEdit();
    },
    cancel() {
      this.editor.commands.setContent(this.oldText);
      this.toggleEdit();
    },
    getHTML() {
      return this.editor.getHTML();
    }
  },
  watch: {
    text(newValue) {
      this.editor.commands.setContent(newValue);
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit, TaskList, TaskItem.configure({
        nested: true
      }), Placeholder.configure({
        placeholder: this.placeholder,
        emptyEditorClass: 'is-editor-empty'
      })],
      content: this.text,
      injectCSS: true,
      editable: false
    });
    if (this.editable) {
      if (!this.optionalEditing) {
        this.editMode = true;
      }
      this.editor.setEditable(this.editMode);
    }
  }
};